<template>
  <div :class="$vuetify.breakpoint.lgAndDown ? 'pr-3 pl-3' : ''">
    <v-col cols="12" align="center">
      <v-col
        cols="12"
        align="left"
        xl="8"
        lg="10"
        md="12"
        sm="12"
        xs="12"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-0' : 'mt-15'"
      >
        <v-row>
          <v-col
            cols="12"
            xl="10"
            lg="10"
            md="10"
            sm="12"
            xs="12"
            class="mt-12"
          >
            <!-- <v-card class="ma-0 pa-0 box" flat> -->
            <p class="ma-0 pa-0 caHeadline">Partners</p>
            <!-- </v-card> -->
          </v-col>
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            xs="12"
            v-for="(item, index) in projectPartners"
            :key="index"
          >
            <v-card class="cardBorder ma-2 pa-7 px-2" height="100%">
              <v-row>
                <v-col cols="12">
                  <v-card
                    height="90px"
                    flat
                    class="d-flex"
                    :href="item.link"
                    target="_blank"
                  >
                    <v-img class="mt-5" :src="item.image" contain />
                  </v-card>
                </v-col>
                <v-col class="pb-0 mb-0" cols="12">
                  <p class="pl-3 partnerTitle">
                    {{ item.title }}
                    <span>
                      <v-btn icon :href="item.link" target="_blank">
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </span>
                  </p>
                </v-col>
                <v-col
                  v-if="selectedIndex !== index"
                  cols="12"
                  class="ma-0 pa-0 pl-3 pr-3"
                >
                  <p class="pl-3 pr-3 partnerDescriptionPre">
                    {{ item.description }}
                  </p>
                </v-col>
                <v-expand-transition>
                  <div v-show="index === selectedIndex">
                    <v-divider></v-divider>
                    <v-card-text>
                      <p class="partnerDescriptionPost">
                        {{ item.description }}
                      </p>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  v-if="selectedIndex !== index"
                  @click="openingPartnerExpand(index)"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                <v-btn icon v-else @click="closingPartnerExpand()">
                  <v-icon large color="black">mdi-chevron-up</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedIndex: null,
      projectPartners: [
        {
          title: "Veksttorget ",
          image: "img/vt-13.png",
          link: "https://veksttorget.no/",
          description: `Veksttorget has over 25 years of experience in helping people at work. 
          
The company is a non-profit company that carries out an important community assignment, and contributes to increased inclusion and value creation in the workplace. 

Veksttorget has special expertise in matching the employer's needs to the job seeker's expertise and resources. 
We use a variety of methods to help the individual in life, including work practices, clarifications, surveys, organized work and competence raising. 
Common to all we help is that they get professional and close follow-up from our job coaches and tutors. 
We work closely with the business community in the region, sharing our work life skills to the companies through guidance and work inclusion. 
`,
        },
        {
          title: "WSEI",
          image: "img/WSEI_granat_tło.jpg",
          link: "https://www.wsei.lublin.pl/",
          description: `University of Economics and Innovation in Lublin (WSEI) is a non-state higher education institution, funded in 2001. 
          
WSEI offers full university degree programmes in a range of academic disciplines. 
With 6 500 students (of which approx. 80 % extramural learners), is a nationally recognized university, demonstrating a commitment to engage in partnerships with EU organizations and institutions to support education, 
training and research. The University maintains close co-operation with many education institutions across Europe, adopting an increasingly international outlook, and is committed to maintaining a high level of research arising from scientific programs along with the development of the collaborative links with industry, commerce and also R&D sector on an international level.
`,
        },
        {
          title: "DIAS",
          image: "img/LogoDIAS.png",
          link: "https://kek-dias.gr/",
          description: `DIAS VET is an educational and counselling organization accredited by the Greek National Organization for the Certification of Qualifications and Vocational Guidance–EOPPEP. 
DIAS VET was established in 2009 in Karditsa and today it also has 2 additional operational offices in other cities. 
Our organisation has been granted ISO 9001:2015 certification. 
This means DIAS implements a quality management system in which all processes and procedures have been carefully designed and quality-tested according to international standards in the field of Vocational Education. 
Our vision is to be evolved into a competitive, European wide VET organization and to provide education and training opportunities, counselling, and support to individuals and businesses by promoting excellence and quality in vocational education and training. 
DIAS VET is aligned with the E.U. strategic framework in fighting the rising levels of unemployment - particularly among young people and confronting the same risk which also affects high number of adult workers (phenomena of social exclusion and the increase of poverty are more and more evident, threatening the social cohesion and standard of living), through the improvement of provided education/training in terms of quality and efficiency. 
DIAS’ mission is to provide certified education & training opportunities, counselling & personal development in order to contribute in quality of life improvements. 
`,
        },
        {
          title: "Eduforma",
          image: "img/logo_eduforma.png",
          link: "http://www.eduforma.it/",
          description: `Eduforma is an Educational Center accredited in Veneto Region for Lifelong Learning training interventions (VET), as well as for Guidance Services and Labour. 
Founded in 2003, was born as a company that has for object the drafting, marketing, organization and delivery of training interventions, information and professional guidance. 
The structure has always favored the monitoring of human resources in companies, starting with an analysis of employees, instead of the organization, and was one of the first in Veneto to introduce skills assessment logic and rewarding systems. 
Eduforma offers training and management consulting services; it is specialized in the development of human resources, increasing people’s skills to face professional problems concerning their specific competences in the company where they work. 
Concentrating on the continuous improvement of business organization, Eduforma helps the management making strategic choices, from the analysis of needs to the intervention planning, from their accomplishment to the analysis of the results. 
Eduforma pursues its missions supporting organizations in increasing their manager’s decision-making skills, responsibility, ability to manage emergencies and the development of teamwork. 
Eduforma is also involved in the production, import and marketing of software and ICT solutions. 
`,
        },
      ],
    };
  },
  methods: {
    openingPartnerExpand(index) {
      this.selectedIndex = index;
    },

    closingPartnerExpand() {
      this.selectedIndex = null;
    },
  },
};
</script>

<style scoped>
/* Card CSS */
/* .box {
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(to right, #dd9a30 5%, transparent 5%);
  border-image-slice: 1;
} */
.cardBorder {
  border: 3px var(--brand-color-main) solid;
  border-radius: 7px;
  transition: 0.2s ease;
}
.cardBorder:hover {
  border: 3px var(--brand-color-secondary) solid;
}

/* Text styling */
.partnerChapterTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 46px;
  color: #434343;
}
.partnerTitle {
  font-family: var(--subtitle-font);
  font-weight: var(--subtitle-weight);
  font-size: var(--subtitle-size);
  color: var(--subtitle-color);
}
.partnerDescriptionPre {
  font-family: var(--body-font);
  font-weight: var(--body-weight);
  color: var(--body-color);
  font-size: var(--body-size);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.partnerDescriptionPost {
  font-family: var(--body-font);
  font-weight: var(--body-weight);
  font-size: var(--body-size);
  color: var(--body-color);
  line-height: var(--body-height);
}
</style>
