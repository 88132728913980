<template>
  <div :class="$vuetify.breakpoint.lgAndDown ? 'pr-3 pl-3' : ''">
    <v-row>
      <v-col align="center" class="mt-15 mb-15">
        <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
          <v-row>
            <!-- Chapter Title -->
            <v-col align="left" cols="12" class="mt-15 pb-0">
              <p class="caHeadline">Welcome to Path</p>
            </v-col>
            <v-col align="left">
              <p>Professional tool for effective career development</p>
              <p>
                PATH is a tool developed for organizations, career counselors,
                youth, students, and people who seek support and assistance in
                making further career choices.
              </p>
              <p>
                In PATH you have the opportunity to have three different
                profiles:
              </p>
              <p class="title">Profile (Organisation)</p>
              <p>
                This profile is for companies that work with career counseling,
                adult education, schools, or similar institutions. Here, your
                company can coordinate and manage the profiles of advisors and
                students.
              </p>
              <p class="title">Profile (Personal account)</p>
              <p>
                Here, anyone who wishes can take the PATH test without being
                connected to an organization or advisor. You can take the test
                and gain access to your results, which can be used for further
                career and study choices.
              </p>
              <p class="title">Advisor</p>
              <p>
                To gain access to the Advisor profile, you must receive an
                invitation from a registered organization. An advisor is someone
                who provides daily guidance to students or others. Here, the
                advisor can invite candidates to take the test, and after the
                student's acceptance, the advisor gains access to the results.
                Afterwards, the advisor can provide the student with guidance
                based on the test results.
              </p>
              <p class="font-weight-bold">
                Click the link below to login / register for the PATH tool
              </p>
              <v-row>
                <v-col cols="12">
                  <v-btn color="primary" class="white--text">
                    <a
                      href="https://path-client-hbwdj.ondigitalocean.app/"
                      target="_blank"
                      style="color: inherit; text-decoration: none"
                    >
                      Login / Register
                    </a>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
