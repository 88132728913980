<template>
  <div :class="$vuetify.breakpoint.lgAndDown ? 'pr-3 pl-3' : ''">
    <v-row>
      <v-col align="center" class="mt-15">
        <v-col cols="12" xl="8" lg="12" md="12" sm="12" xs="12">
          <v-row>
            <v-col align="left" cols="12" class="mt-15">
              <p class="caHeadline">Resources</p>
            </v-col>
            <v-row>
              <v-col v-for="(item, index) in resourceFolders" :Key="index" cols="12" xl="4" lg="4" md="6" sm="12" xs="12">
                <v-card @click="displayResource(item.template)" class="resourceCard">
                  <p v-if="item.title == 'Video' || item.title == 'Video'" class="text-center caSubtitle pt-12 pb-8">{{ $t("resources.video") }}</p>
                  <p v-else-if="item.title == 'Files' || item.title == 'Filer'" class="text-center caSubtitle pt-12 pb-8">
                    {{ $t("resources.files") }}
                  </p>
                  <p v-else-if="item.title == 'Courses' || item.title == 'Kurs'" class="text-center caSubtitle pt-12 pb-8">
                    {{ $t("resources.courses") }}
                  </p>
                  <p v-else-if="item.title == 'Podcast' || item.title == 'Podcast'" class="text-center caSubtitle pt-12 pb-8">
                    {{ $t("resources.podcast") }}
                  </p>
                  <p v-else-if="item.title == 'Sound' || item.title == 'Lyd'" class="text-center caSubtitle pt-12 pb-8">
                    {{ $t("resources.sound") }}
                  </p>
                  <p v-else-if="item.title == 'Other' || item.title == 'Annet'" class="text-center caSubtitle pt-12 pb-8">
                    {{ $t("resources.other") }}
                  </p>
                  <p v-else>...</p>
                  <!-- <v-divider class="newsCardDividerPositioning" style="border: 0" :style="`background-color:${colorArr[index]}`"></v-divider>
                  <v-col cols="12" class="ma-0 pa-0" style="padding-top: 6px !important"></v-col> -->
                </v-card>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <v-col align="center" class="mb-15">
        <v-col cols="12" xl="8" lg="12" md="12" sm="12" xs="12">
          <v-row>
            <v-row>
              <v-col cols="12" class="pt-5 mb-15">
                <!-- <v-card flat> -->
                <v-row>
                  <!-- Default -->
                  <template v-if="resourceTemplate == 0">
                    <!-- <v-col cols="12">
                      <p class="text-center title">HOME</p>
                    </v-col> -->
                  </template>

                  <!-- No content added yet -->
                  <template v-else-if="resourceContent == ''">
                    <v-col cols="12" align="left" xl="9" lg="9" md="9" sm="12" xs="12" class="pb-0">
                      <p class="caTitle mt-4">No content added</p>
                    </v-col>
                    <v-col cols="12" align="right" align-self="center" xl="3" lg="3" md="3" sm="12" xs="12" class="d-flex align-end flex-column mt-3">
                      <!-- <v-btn @click="closeTheResourceBox()">X</v-btn> -->
                      <!-- <span @click="closeTheResourceBox()" class="material-icons-outlined closeIcon">close</span> -->
                      <span
                        @click="closeTheResourceBox()"
                        class="material-icons-outlined iconbutton closeIcon text-center mr-2"
                        style="background-image: url('/img/icon button bg green.svg'); margin-bottom: 2px"
                      >
                        <div style="transform: translateY(12px)">close</div>
                      </span>
                    </v-col>
                  </template>

                  <!-- Display Content -->
                  <template v-else-if="resourceContent">
                    <v-col cols="12" align="left" xl="9" lg="9" md="9" sm="12" xs="12" class="pb-0">
                      <p class="caTitle mt-4">Resource Content</p>
                    </v-col>
                    <v-col cols="12" align="right" align-self="center" xl="3" lg="3" md="3" sm="12" xs="12" class="d-flex align-end flex-column mt-3">
                      <!-- <v-btn @click="closeTheResourceBox()">X</v-btn> -->
                      <!-- <span @click="closeTheResourceBox()" class="material-icons-outlined closeIcon">close</span> -->
                      <span
                        @click="closeTheResourceBox()"
                        class="material-icons-outlined iconbutton closeIcon text-center mr-2"
                        style="background-image: url('/img/icon button bg green.svg'); margin-bottom: 2px"
                      >
                        <div style="transform: translateY(12px)">close</div>
                      </span>
                    </v-col>
                    <v-col
                      class="pa-3"
                      v-for="(resources, resourceIndex) in resourceContent"
                      :key="resourceIndex"
                      cols="12"
                      xl="6"
                      lg="6"
                      md="12"
                      sm="12"
                      xs="12"
                    >
                      <v-col style="box-shadow: 0px 2px 16px #00000033; border-radius: 7px" class="pa-3" cols="12">
                        <!-- <pre>{{resource}}</pre> -->
                        <v-card height="100%" flat>
                          <!-- Media above title, sort by type -->
                          <p class="caSubtitle mb-3 ml-4 mt-1 text-left">{{ resources.title }}</p>
                          <!-- {{ $t('resource.other') }} -->
                          <!-- Languages -->

                          <v-col cols="12" v-for="(language, languageIndex) in resources.languages" :Key="languageIndex" class="pb-0 pt-0 mb-4">
                            <v-card v-if="language.link" height="100%" @click="test(language.link)" class="resourceCard">
                              <!-- <pre>{{language}}</pre> -->
                              <v-row>
                                <v-col cols="1" align-self="center" class="ml-4">
                                  <v-img
                                    v-if="language.language == 'English'"
                                    src="@/assets/flags/england.png"
                                    alt="Norwegian Flag"
                                    max-height="30"
                                    max-width="30"
                                    contain
                                  ></v-img>
                                  <v-img
                                    v-if="language.language == 'Italian'"
                                    src="@/assets/flags/italy.png"
                                    alt="Norwegian Flag"
                                    max-height="25"
                                    max-width="25"
                                    contain
                                    class="ml-1"
                                  ></v-img>
                                  <v-img
                                    v-if="language.language == 'Norwegian'"
                                    src="@/assets/flags/norway.png"
                                    alt="Norwegian Flag"
                                    min-height="25"
                                    min-width="25"
                                    max-height="25"
                                    max-width="25"
                                    contain
                                    class="ml-1"
                                  ></v-img>
                                  <v-img
                                    v-if="language.language == 'Danish'"
                                    src="@/assets/flags/denmark.png"
                                    alt="Norwegian Flag"
                                    max-height="25"
                                    max-width="25"
                                    contain
                                    class="ml-1"
                                  ></v-img>
                                </v-col>
                                <v-col align="left" class="title pt-5" style="transform: translateY(3px)">
                                  <p>{{ language.language }}</p>
                                </v-col>
                                <v-col align="right" align-self="center">
                                  <!-- <span
                                    class="material-icons-outlined iconbutton text-center mr-2"
                                    style="background-image: url('/img/icon button bg green.svg'); margin-bottom: 2px"
                                  >
                                    <div style="transform: translateY(12px)" class="">open_in_new</div>
                                  </span> -->
                                  <span class="material-icons-outlined" style="transform: translateY(3px) translateX(-19px)">open_in_new</span>
                                </v-col>
                              </v-row>
                              <!-- <v-divider></v-divider> -->
                            </v-card>
                          </v-col>
                        </v-card>
                      </v-col>
                    </v-col>
                  </template>
                </v-row>
                <!-- </v-card> -->
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <!-- Resource Content -->
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      colorArr: ["#205072", "#329D9C", "#D83636", "#DD9A30", "#205072", "#329D9C"],
      resourceFolders: [
        { title: this.$t("resources.video"), colour: "", image: "", description: "", template: 1 },
        { title: this.$t("resources.files"), colour: "", image: "", description: "", template: 2 },
        { title: this.$t("resources.courses"), colour: "", image: "", description: "", template: 3 },
        { title: this.$i18n.t("resources.podcast"), colour: "", image: "", description: "", template: 4 },
        { title: this.$i18n.t("resources.sound"), colour: "", image: "", description: "", template: 5 },
        { title: this.$i18n.t("resources.other"), colour: "", image: "", description: "", template: 6 },
      ],
      /* Easy way to display the content
      
      */
      resourceTemplate: 0,
      resourceContent: [],
      dummyRecourseContent: [
        {
          title: "Video 1",
          content: "",
          type: "",
          languages: [
            { language: "English", link: "https://www.google.com/" },
            { language: "Italian", link: "https://www.vg.no/" },
            { language: "Norwegian", link: "https://www.vg.no/" },
            { language: "Danish", link: "https://www.google.com/" },
          ],
        },
        {
          title: "Video 2",
          content: "",
          type: "",
          languages: [
            { language: "English", link: "https://www.google.com/" },
            { language: "Italian", link: "https://www.google.com/" },
            { language: "Norwegian", link: "https://www.t-a.no/" },
            { language: "Danish", link: "https://www.google.com/" },
          ],
        },
      ],
    };
  },
  methods: {
    // Close the Bottom Box
    closeTheResourceBox() {
      this.resourceTemplate = 0;
      this.resourceContent = [];
    },

    // Display the specific Resource Content
    displayResource(templateID) {
      this.resourceTemplate = templateID;
      if (this.resourceTemplate == 1) {
        this.resourceContent = this.dummyRecourseContent;
      } else {
        this.resourceContent = [];
      }
    },
    test(link) {
      window.open(link);
    },
  },
};
</script>

<style scoped>
.closeIcon {
  cursor: pointer;
  transition: ease 0.2s;
  /* color: var(--brand-color-main); */
}
.closeIcon:hover {
  color: white;
}
.resourceCard {
  border-radius: 7px !important;
  border: 2px solid var(--brand-color-main) !important;
  box-shadow: 0px 2px 16px #00000033 !important;
  transition: ease 0.4s;
}
.resourceCard:hover {
  box-shadow: 0px 2px 16px #00000080 !important;
  border: 2px solid var(--brand-color-secondary) !important;
}
.resourceCard:hover .iconbutton {
  color: white;
}
.newsCardDividerPositioning {
  border-radius: 4px;
  padding: 3px;
  width: calc(100% - 12px);
}
</style>
